import store from "@/store";
import router from "@/router";
import { Actions } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import { notif } from '@/store/stateless/store';
// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class PromotionCodeService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    PromotionCodeService.vueInstance = app;
  }


  public static getPromotionCodeList(payload, index = 1) {
    console.log('index',payload)
    payload.index = index;
   
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_PROMOTION_CODE_LIST, payload)
        .then((data) => {

          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });

  }

  public static getPromotionCodeTypes() {
    return new Promise<void>((resolve, reject) => {
    store
        .dispatch(Actions.GET_PROMOTION_CODE_TYPES)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })

  }

  public static addPromotionCode(data) {
    store.dispatch(Actions.ADD_PROMOTION_CODE, data)
      .then((data) => {
        // 
        // Swal.fire({
        //   text: "Promotion code has been added successfully !",
        //   icon: "success",
        //   buttonsStyling: false,
        //   showConfirmButton: false,
         
        //   showCloseButton: true,
        //   showOkButton: false,
          
        // }).then(() => {
        // });
        notif.simple('Promo Code', 'success', 'Promotion code has been added successfully!');
        router.push({ name: "promotion-code-list" });

      })
      .catch((error) => {
        // 
      })
  }

  public static showPromotionCode(payload) {
    return new Promise<void>((resolve, reject) => {
    store
        .dispatch(Actions.FETCH_PROMOTION_CODE, payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })

  }


  public static updatePromotionCode(data) {
    store.dispatch(Actions.UPDATE_PROMOTION_CODE, data)
      .then((data) => {
        // 
        // Swal.fire({
        //   text: "Promotion code has been updated successfully !",
        //   icon: "success",
        //   buttonsStyling: false,
        //   showConfirmButton: false,
         
        //   showCloseButton: true,
        //   showOkButton: false,

        // }).then(() => {
        // });
        notif.simple('Promo Code', 'success', 'Promotion code has been updated successfully!');
        router.push({ name: "promotion-code-list" });

      })
      .catch((error) => {
        // 
      })
  }



}
export default PromotionCodeService;